<template>
  <div class="fixed left-1/2 top-16 z-[60] flex -translate-x-1/2 flex-col gap-2 sm:bottom-12 sm:top-auto">
    <TransitionGroup :name="isMobile ? 'slideY' : 'reverseSlideY'">
      <div
        v-for="snack in uiStore.getSnackBanners"
        :id="`snack-banner-${snack.id}`"
        :key="snack.id"
        class="flex h-fit w-10/12 flex-row items-stretch rounded-2xl text-white shadow sm:w-8/12 lg:w-[898px]"
      >
        <div
          class="flex flex-col items-center justify-center rounded-l-2xl p-2 sm:p-5"
          :class="{
            'bg-success-100': snack.type === 'success',
            'bg-alert-100': snack.type === 'info',
            'bg-error-100': snack.type === 'error',
          }"
        >
          <UiIcon
            :name="iconMap[snack.type]"
            :class="{
              'fill-success-100': snack.type === 'success',
              'fill-alert-100': snack.type === 'info',
              'fill-error-100': snack.type === 'error',
            }"
          ></UiIcon>
        </div>
        <div
          class="text-caption-3 sm:text-body flex flex-1 items-center rounded-r-2xl bg-black-90 px-2 py-3 text-left sm:rounded-none sm:px-8 sm:py-6"
        >
          {{ snack.text }}
        </div>
        <div v-if="snack.action" class="flex flex-row items-center justify-center gap-4 rounded-r-2xl bg-black-90 p-6">
          <UiButtonBase
            id="close"
            type="secondary"
            class="border-white text-white"
            @click="executeSecondaryAction(snack)"
          >
            {{ snack.secondaryActionButtonText || 'Close' }}
          </UiButtonBase>
          <UiButtonBase id="action_button" @click="executeAction(snack)">{{ snack.actionButtonText }}</UiButtonBase>
        </div>
        <div v-else class="hidden w-1/12 flex-col items-center justify-center rounded-r-2xl bg-black-90 p-6 sm:flex">
          <UiIcon name="big-close" class="cursor-pointer" @click="uiStore.resetSnackBanner(snack.id)"></UiIcon>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { SnackBanner } from '~/types'

const iconMap = {
  success: 'check-filled',
  info: 'info-circle-filled',
  error: 'alert-triangle-filled',
}
const uiStore = useUiStore()

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')

const resetTimers = ref<number[]>([])

watch(
  () => uiStore.getSnackBanners,
  (value) => {
    if (value) {
      const ids = value.map((snack) => snack.id)

      ids.forEach((id, index) => {
        if (!resetTimers.value.includes(id)) {
          resetTimers.value.push(id)

          setTimeout(() => {
            uiStore.resetSnackBanner(id)

            resetTimers.value = resetTimers.value.filter((timer) => timer !== id)
          }, 4000 + index * 1000)
        }
      })
    }
  },
  {
    deep: true,
  }
)

const executeSecondaryAction = (snack: SnackBanner) => {
  if (snack.secondaryAction) {
    snack.secondaryAction()
  }

  uiStore.resetSnackBanner(snack.id)
}

const executeAction = (snack: SnackBanner) => {
  if (snack.action) {
    snack.action()
  }

  uiStore.resetSnackBanner(snack.id)
}
</script>

<style scoped></style>
