import { default as usersXnEF1nfndWMeta } from "/usr/src/app/pages/admin-settings/users.vue?macro=true";
import { default as admin_45settingsQE9lZPgAJUMeta } from "/usr/src/app/pages/admin-settings.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as all9wJxf1tOmcMeta } from "/usr/src/app/pages/properties/all.vue?macro=true";
import { default as exclusivesusvTtFPSvfMeta } from "/usr/src/app/pages/properties/exclusives.vue?macro=true";
import { default as properties5I0VgbXTlXMeta } from "/usr/src/app/pages/properties.vue?macro=true";
import { default as _91id_93fZX8Iw32z3Meta } from "/usr/src/app/pages/property/[id].vue?macro=true";
import { default as callback2SLl5yLrKYMeta } from "/usr/src/app/pages/sso/callback.vue?macro=true";
export default [
  {
    name: "admin-settings",
    path: "/admin-settings",
    meta: admin_45settingsQE9lZPgAJUMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings.vue"),
    children: [
  {
    name: "admin-settings-users",
    path: "users",
    component: () => import("/usr/src/app/pages/admin-settings/users.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "properties",
    path: "/properties",
    meta: properties5I0VgbXTlXMeta || {},
    component: () => import("/usr/src/app/pages/properties.vue"),
    children: [
  {
    name: "properties-all",
    path: "all",
    component: () => import("/usr/src/app/pages/properties/all.vue")
  },
  {
    name: "properties-exclusives",
    path: "exclusives",
    component: () => import("/usr/src/app/pages/properties/exclusives.vue")
  }
]
  },
  {
    name: "property-id",
    path: "/property/:id()",
    meta: _91id_93fZX8Iw32z3Meta || {},
    component: () => import("/usr/src/app/pages/property/[id].vue")
  },
  {
    name: "sso-callback",
    path: "/sso/callback",
    meta: callback2SLl5yLrKYMeta || {},
    component: () => import("/usr/src/app/pages/sso/callback.vue")
  }
]